<template>
  <section
    class="box-height performanceConfig"
    style="height: 100%; padding: 0 15px !important"
  >
    <!-- 标题 -->
    <div
      class="wrapperData"
      style="border: 1px #e5e5e5 solid; padding-bottom: 40px"
    >
      <el-form
        :model="filter"
        ref="filter"
        class="lang"
        style="padding: 0 15px"
      >
        <el-row style="display: flex">
          <el-form-item label="年份：" prop="year" label-width="50px">
            <el-date-picker
              v-model="filter.year"
              type="year"
              value-format="yyyy"
              @change="getDate"
            ></el-date-picker>
          </el-form-item>
          <el-form-item
            prop="objectIds"
            label="对象："
            label-width="50px"
            style="margin-left: 20px"
          >
            <el-select
              v-model="filter.type"
              placeholder="请选择大类"
              clearable
              filterable
              @change="changeOption"
              @clear="cleanData"
              style="width: 110px"
              class="dinput"
            >
              <el-option
                v-for="item in optionsList"
                :key="item.type"
                :label="item.name"
                :value="item.name"
              >
              </el-option>
            </el-select>
            <el-cascader
              style="width: 250px"
              :filterable="true"
              clearable
              :options="options2"
              collapse-tags
              :show-all-levels="false"
              v-model="searchIdList"
              :props="props"
              placeholder="请选择小类"
              ref="dataRef"
              @change="getSearch"
              class="dinputr"
              :filter-method="filterFun"
            >
            </el-cascader>
          </el-form-item>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24"
            ><el-button size="mini" @click="showDig">设置</el-button>
            <el-pagination
              style="float: right"
              :hide-on-single-page="filter.page.total > 10 ? false : true"
              v-if="filter.page.total > 0"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="filter.page.current"
              :page-size="filter.page.size"
              :pager-count="5"
              layout="total,prev, pager, next,sizes"
              :total="filter.page.total"
              :page-sizes="[10, 20, 50, 100]"
            ></el-pagination
          ></el-col>
        </el-row>
      </el-form>
      <div style="margin: 10px 0 0 0">
        <el-table
          :data="listData"
          v-loading="listLoading"
          :header-cell-style="{
            'background-color': '#f5f7fa',
          }"
          class="tabBorder custor pctable notableBorder"
          style="width: 100%"
        >
          <el-table-column prop="objectName" label="对象" width="120">
          </el-table-column>
          <el-table-column prop="type" label="类型"> </el-table-column>
          <el-table-column prop="year" label="年份"> </el-table-column>
          <el-table-column prop="januaryValue" label="1月" width="150">
          </el-table-column>
          <el-table-column
            prop="februaryValue"
            label="2月"
            width="150"
          ></el-table-column>
          <el-table-column prop="marchValue" label="3月" width="150">
          </el-table-column>
          <el-table-column prop="aprilValue" label="4月" width="150">
          </el-table-column>
          <el-table-column prop="mayValue" label="5月" width="150">
          </el-table-column>
          <el-table-column prop="juneValue" label="6月" width="150">
          </el-table-column>
          <el-table-column
            prop="julyValue"
            label="7月"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="augustValue"
            label="8月"
            width="150"
          ></el-table-column>
          <el-table-column prop="septemberValue" label="9月" width="150">
          </el-table-column>
          <el-table-column
            prop="octoberValue"
            label="10月"
            width="150"
          ></el-table-column>
          <el-table-column prop="novemberValue" label="11月" width="150">
          </el-table-column>
          <el-table-column prop="decemberValue" label="12月" width="150">
          </el-table-column>
          <el-table-column prop="createdUserName" label="操作人" width="120">
          </el-table-column>
          <el-table-column fixed label="操作" width="130">
            <template #default="scope">
              <span @click="handleEdit(scope.row)" class="tabHref">编辑</span>
              <el-divider direction="vertical"></el-divider>
              <span @click="delectRow(scope.row.id)" class="tabHref">删除</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :hide-on-single-page="filter.page.total > 10 ? false : true"
          v-if="filter.page.total > 0"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="filter.page.current"
          :page-size="filter.page.size"
          :pager-count="5"
          layout="total,prev, pager, next,sizes"
          :total="filter.page.total"
          :page-sizes="[10, 20, 50, 100]"
        ></el-pagination>
      </div>
      <el-drawer
        :visible.sync="dialogVisible"
        append-to-body
        :modal="false"
        custom-class="drawerClass"
        :close-on-click-modal="false"
        direction="ttb"
        :before-close="closeDig"
        destroy-on-close
      >
        <div slot="title" class="drawerTitle">
          {{ titName }}
        </div>
        <div class="drawerCon">
          <addConfig
            ref="addConfig"
            @changeSaveLoading="changeSaveLoading"
            @handleCloseDrawer="closeDig"
            @loadlist="getList"
          ></addConfig>
          <div class="drawerCon_footer fixedFoot">
            <el-button @click="dialogVisible = false" size="small"
              >取 消</el-button
            >
            <el-button
              type="primary"
              :loading="saveloading"
              size="small"
              @click="save"
              style="margin-right: 15px"
              >确 定</el-button
            >
          </div>
        </div>
      </el-drawer>
      <el-drawer
        :visible.sync="drawerVisible"
        append-to-body
        :modal="false"
        custom-class="drawerClass"
        :close-on-click-modal="false"
        direction="ttb"
        :before-close="closeDig1"
        destroy-on-close
      >
        <div slot="title" class="drawerTitle">编辑</div>
        <div class="drawerCon">
          <el-table
            :data="editData"
            :header-cell-style="{
              'background-color': '#f5f7fa',
            }"
            class="tabBorder custor pctable notableBorder"
            style="width: 100%"
          >
            <el-table-column prop="objectName" label="对象" width="120">
            </el-table-column>
            <el-table-column prop="type" label="类型"> </el-table-column>
            <el-table-column prop="year" label="年份"> </el-table-column>
            <el-table-column prop="januaryValue" label="1月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.januaryValue"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="februaryValue" label="2月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.februaryValue"
                >
                </el-input> </template
            ></el-table-column>
            <el-table-column prop="marchValue" label="3月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.marchValue"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="aprilValue" label="4月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.aprilValue"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="mayValue" label="5月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.mayValue"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="juneValue" label="6月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.juneValue"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="julyValue" label="7月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.julyValue"
                >
                </el-input> </template
            ></el-table-column>
            <el-table-column prop="augustValue" label="8月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.augustValue"
                >
                </el-input> </template
            ></el-table-column>
            <el-table-column prop="septemberValue" label="9月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.septemberValue"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="octoberValue" label="10月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.octoberValue"
                >
                </el-input> </template
            ></el-table-column>
            <el-table-column prop="novemberValue" label="11月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.novemberValue"
                >
                </el-input>
              </template>
            </el-table-column>
            <el-table-column prop="decemberValue" label="12月" width="150">
              <template #default="scope">
                <el-input
                  type="number"
                  placeholder="请输入内容"
                  v-model.number="scope.row.decemberValue"
                >
                </el-input>
              </template>
            </el-table-column>
          </el-table>
          <div class="drawerCon_footer fixedFoot">
            <el-button @click="drawerVisible = false" size="small"
              >取 消</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="submit"
              style="margin-right: 15px"
              >确 定</el-button
            >
          </div>
        </div>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {
  permissionType,
  permissionTree,
  performanceTargetSetting,
  performanceTargetSettingEdit,
  deletePerformanceTargetSetting,
} from "../../api/api";
import addConfig from "../../components/report/add";
export default {
  components: {
    addConfig,
  },
  data() {
    const year = new Date().getFullYear().toString();
    return {
      filter: {
        objectIds: [],
        type: "",
        year: year,
        page: {
          asc: [],
          ascs: [],
          countId: "",
          desc: [],
          descs: [],
          hitCount: true,
          maxLimit: null,
          optimizeCountSql: true,
          orders: [
            {
              asc: true,
              column: "",
            },
          ],
          current: 1,
          size: 50,
          searchCount: true,
          pages: null,
          records: [],
          total: 0,
        },
      },
      objectIds: [],
      props: {
        value: "id",
        label: "name",
        children: "children",
        multiple: true,
      },
      optionsList: [],
      options2: [],
      listData: [],
      listLoading: false,
      dialogVisible: false,
      titName: "",
      saveloading: false,
      searchIdList: [],
      userId: parseInt(this.getStore("uid")),
      sourceIds: [],
      drawerVisible: false,
      editData: [],
    };
  },
  watch: {
    drawerVisible(newValue) {
      if (newValue) {
        this.noScroll(); //禁止滚动
      } else {
        this.canScroll();
      }
    },
  },
  created() {
    this.permissionType();
    this.getList();
  },
  methods: {
    // 查询维度
    // 大类
    permissionType() {
      permissionType().then((res) => {
        if (res.success) {
          this.optionsList = res.result;
        }
      });
    },
    // 小类
    permissionTree(searchType, isfirst) {
      permissionTree({ type: searchType }).then((res) => {
        if (res.success) {
          this.options2 = res.result;
          if (isfirst) {
            this.searchIdList = [];
          }
        }
      });
    },
    //回显(多级)
    changeDetSelect(key, treeData) {
      let arr = []; // 在递归时操作的数组
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数
      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (
              childrenData[j].children &&
              childrenData[j].children.length > 0
            ) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }

      return childrenEach(treeData, depth);
    },
    changeOption(val) {
      this.sourceIds = [];
      this.filter.page.current = 1;
      if (val) {
        this.permissionTree(val);
      }
      this.searchIdList = [];
      this.getList();
    },
    //年份
    getDate(e) {
      this.filter.year = e;
      this.getList();
    },
    //查询
    getList() {
      if (this.filter.type !== "" && this.sourceIds.length == 0) {
        this.$message.error("对象必填，请选择对象！");
        this.ListData = [];
        return false;
      }
      this.listLoading = true;
      this.filter.objectIds = this.sourceIds;
      performanceTargetSetting(this.filter).then((res) => {
        if (res.success) {
          this.listLoading = false;
          this.listData = res.result.records;
          this.filter.page.total = res.result.total;
        } else {
          this.listLoading = false;
        }
      });
    },
    handleSizeChange(val) {
      //pageSize 改变时会触发
      this.filter.page.size = val;
      this.filter.page.current = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      //currentPage 改变时会触发
      this.filter.page.current = val;
      this.getList();
    },
    getSearch(val) {
      const _nodeId = this.$refs.dataRef.getCheckedNodes();
      this.sourceIds = this.searchIdList.map((i) => {
        return i[i.length - 1];
      });
      this.getList();
    },
    //编辑
    handleEdit(row) {
      this.editData[0] = row;
      this.drawerVisible = true;
    },
    //设置表格
    submit() {
      if (this.editData[0].januaryValue == "-") {
        this.editData[0].januaryValue = null;
      }
      if (this.editData[0].februaryValue == "-") {
        this.editData[0].februaryValue = null;
      }
      if (this.editData[0].marchValue == "-") {
        this.editData[0].marchValue = null;
      }
      if (this.editData[0].aprilValue == "-") {
        this.editData[0].aprilValue = null;
      }
      if (this.editData[0].mayValue == "-") {
        this.editData[0].mayValue = null;
      }
      if (this.editData[0].juneValue == "-") {
        this.editData[0].juneValue = null;
      }
      if (this.editData[0].julyValue == "-") {
        this.editData[0].julyValue = null;
      }
      if (this.editData[0].augustValue == "-") {
        this.editData[0].augustValue = null;
      }
      if (this.editData[0].septemberValue == "-") {
        this.editData[0].septemberValue = null;
      }
      if (this.editData[0].octoberValue == "-") {
        this.editData[0].octoberValue = null;
      }
      if (this.editData[0].novemberValue == "-") {
        this.editData[0].novemberValue = null;
      }
      if (this.editData[0].decemberValue == "-") {
        this.editData[0].decemberValue = null;
      }
      performanceTargetSettingEdit(this.editData[0]).then((res) => {
        if (res.success) {
          this.$message.success("编辑成功");
          this.drawerVisible = false;
          this.getList();
        }
      });
    },
    //删除
    delectRow(id) {
      this.$confirm("确认删除吗?", "提示", {
        customClass: "altCls",
        type: "warning",
      })
        .then(() => {
          deletePerformanceTargetSetting(id).then((res) => {
            if (res.success) {
              this.$message.success("删除成功");
              this.getList();
            }
          });
        })
        .catch(() => {});
    },
    //弹出弹窗
    showDig() {
      this.titName = "新增";
      this.dialogVisible = true;
    },
    //关闭弹窗
    closeDig() {
      this.dialogVisible = false;
    },
    //防抖节流
    changeSaveLoading(val) {
      this.saveloading = val;
    },
    //保存
    save() {
      this.$refs.addConfig.saveInfo();
    },
    //关闭弹窗
    closeDig1() {
      this.drawerVisible = false;
    },
    cleanData() {
      this.options2 = [];
    },
    filterFun(data, value) {
      // 弹窗 - 拼音过滤
      return data.data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapperData {
  background: #fff;
  padding: 20px 0;
}
.tabHref {
  &:hover {
    color: #526ecc;
  }
}
.fixedFoot {
  z-index: 9999 !important;
}
</style>
<style lang="scss">
.dinput {
  .el-input--suffix .el-input__inner {
    border-radius: 5px 0px 0px 5px;
  }
}
.dinputr {
  border-radius: 0 5px 5px 0;
  border-left: 0;
  .el-input--suffix .el-input__inner {
    border-radius: 0 5px 5px 0;
    border-left: 0;
  }
}
.pctable {
  .el-input-group__append {
    &:hover {
      // background: rgba(230,0,18,0.5);
      color: #e60012;
      // border: #e60012 solid 1px;
    }
  }
  .el-input__inner {
    height: 28px !important;
  }
}
.performanceConfig {
  .el-table .cell {
    padding-left: 20px;
  }
}
</style>